import "./App.css";
import Pulse from "react-reveal/Pulse";
import Slide from "react-reveal/Slide";

import foto1 from "./images/006.jpg";
import foto2 from "./images/007-2.jpg";
import foto3 from "./images/173.jpg";
import foto4 from "./images/294.jpg";
import foto5 from "./images/4.jpg";
import foto6 from "./images/615A0196.jpg";
import foto7 from "./images/615A1865.jpg";
import foto8 from "./images/615A4643.jpg";
import foto9 from "./images/615A6540.jpg";
import foto10 from "./images/615A7839-2.jpg";
import foto11 from "./images/629.jpg";

function App() {
  return (
    <div className="App">
      <div className="page">
        <header className="header">
          <h1 className="funny-title section-title">ICANON</h1>
          <Pulse>
            <h2>Семейный фотограф </h2>
            <h2 className="header__title">
              Евгения Ильчук
            </h2>
          </Pulse>
        </header>

        {/* Блок фото */}
        <section className="section__foto">
          <div className="grid-wrapper">
            <div>
              <Pulse>
                <img src={foto1} className="img-foto" alt="" />
              </Pulse>
            </div>
            <div className="wide">
              <Slide left>
                <img src={foto4} className="img-foto" alt="" />
              </Slide>
            </div>
            <div className="tall">
              <Pulse>
                <img src={foto2} className="img-foto" alt="" />
              </Pulse>
            </div>
            <div className="big">
              <Slide right>
                <img src={foto6} className="img-foto" alt="" />
              </Slide>
            </div>
            <div>
              <Pulse>
                <img src={foto11} className="img-foto" alt="" />
              </Pulse>
            </div>
            <div>
              <Slide left>
                <img src={foto8} className="img-foto" alt="" />
              </Slide>
            </div>
            <div className="big">
              <Pulse>
                <img src={foto9} className="img-foto" alt="" />
              </Pulse>
            </div>
            <div className="tall">
              <Slide right>
                <img src={foto10} className="img-foto" alt="" />
              </Slide>
            </div>
            <div className="big">
              <img src={foto3} className="img-foto" alt="" />
            </div>
          </div>
        </section>

        <section className="section section_fon">
          <div className="section__text-container section__text-container-section">
            <Pulse>
              <h2 className=" section__text_title">
                Запечатлейте яркие моменты жизни вашей семьи!
              </h2>
            </Pulse>
            <Slide right>
              <p className="section__text">
                Профессиональный фотограф поможет вам сохранить самые ценные и
                важные моменты! Я&#160;предлагаю индивидуальный подход к каждой
                семье, мы вместе создадим уникальные и запоминающиеся
                фотографии, которые будут радовать вас и ваших близких долгие
                годы.{" "}
              </p>
            </Slide>
            <Slide left>
              <p className="section__text">
                Моя работа - это не просто фотография, это история вашей семьи.
                Я&#160;помогу вам создать атмосферу, которая передаст все эмоции и
                чувства, что вы испытываете в этот момент.{" "}
              </p>
            </Slide>
          </div>
        </section>

        {/* Краткий текст и контакты */}
        <div className="section">
          <div className="section__text-container-contact">
            <Slide left>
              <img src={foto5} alt="фото" className="section__text-foto" />
            </Slide>
            <Slide right>
              <div className="section__text-container-section">
                <h3 className="section__contact">Не откладывайте на потом!</h3>
                <p className="section__contact">
                  Запишитесь на фотосессию уже сегодня и получите незабываемый
                  подарок для вашей семьи!
                </p>
                <div className="section__link-container">
                  <a href="tel:+79606111661" className="link" target="_blank">
                    <button className="link__phone">+7 960 611-16-61</button>
                  </a>
                  <a
                    href="https://vk.me/public171238676"
                    title="Перейти в ВК"
                    target="_blank"
                  >
                    <button className="link__vk"></button>
                  </a>
                </div>
              </div>
            </Slide>
          </div>
        </div>
        <div className="footer">
          <p className="">&copy; {new Date().getFullYear()} Евгения Ильчук</p>
        </div>
      </div>
    </div>
  );
}

export default App;
